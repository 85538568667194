<template>
<div class="list">

<div class="listMain wrap">
<div class="listMainBox">
<div class="listMainLeft fl">
<div class="tit">{{slideHdTitle}}</div>
<div class="sort">
<div v-for="(item, index) in slideList" :key="index">
<a 
                v-if="item.url && item.url !== ''" 
                target="_blank" 
                :class="{ck : active  == item.entTChannelUuid}"
                :href="item.url">
                {{item.name}}
              </a>
<a 
                v-if="!item.url || item.url == ''" 
                :class="{ck : active  == item.entTChannelUuid}"
                :href="item.catalog === 1 ? `/listPage/${item.entTChannelUuid}` : item.catalog === 2 ? `/list/${item.entTChannelUuid}` : `/listPic/${item.entTChannelUuid}`">
                {{item.name}}
              </a>
</div>
</div>
<!-- <div class="listSlid">
<div class="listSlidHd">
              {{slideHdTitle}}
            </div>
<div class="listSlidList">
<div class="listSlidListInner" :class="{on : active  == item.entTChannelUuid}" v-for="(item, index) in slideList" :key="index">
<a 
                  v-if="item.url && item.url !== ''" 
                  target="_blank" 
                  :href="item.url">
                  {{item.name}}
                </a>
<a 
                  v-if="!item.url || item.url == ''" 
                  :href="item.catalog === 1 ? `/listPage/${item.entTChannelUuid}` : item.catalog === 2 ? `/list/${item.entTChannelUuid}` : `/listPic/${item.entTChannelUuid}`">
                  {{item.name}}
                </a>
<span></span>
</div>
</div>
</div>-->
</div>
<div class="listMainRight fr">
<div class="listWrap">
<div class="listHd clearfloat">
<div class="fl listHdGropL">
<span>{{slideHdName}}</span>
</div>
<div class="fr listHdGrop">
<span class="listHdIco"></span>您当前的位置：<a href="/">首页</a>><span v-if="viewSlideHdParent"><a :href="slideHdParentUrl">{{slideHdTitle}}</a>></span><span class="txt">{{slideHdName}}</span>
</div>
</div>
<div class="listContainer">
<div class="detailHd">
<div class="detailTitle">{{pageCont.title}}</div>
<div class="detailTitleSub">{{pageCont.ftitle}}</div>
<div class="detailTitleB">
<span>时间：<em>{{dayjs(pageCont.publishDate).format('YYYY-MM-DD')}}</em></span>
<span>浏览次数：<em>{{pageCont.click}}</em>次</span>
<span>作者：<em>{{pageCont.author}}</em></span>
</div>
</div>
<div class="detailCont" v-html="pageCont.content">
</div>
<!-- <div class="detailJump">
<div class="pageEditor">责编：{{pageCont.author}}</div>
<div class="detailJumpBox">
<p v-if="pageCont.upArtUuid && pageCont.upArtUuid !== '0'">
<a target="_blank" :href="pageCont.upArtUuid">上一篇：{{pageCont.upArtTitle}}</a>
</p>
<p v-if="pageCont.downArtUuid && pageCont.downArtUuid !== '0'">
<a target="_blank" :href="pageCont.downArtUuid">下一篇：{{pageCont.downArtTitle}}</a>
</p>
</div>
</div>-->
</div>
</div>
</div>
</div>
</div>
</div>
</template>
<script>
import { getChildChannel, getChannelInfo, getArtInfo, increaseViews } from '@/api/common'
export default {
  name: 'list',
  data(){
    return {
      parentId: '',
      slideHdTitle: '',
      viewSlideHdParent: true,
      slideHdParentName: '',
      slideHdParentUrl: '',
      slideHdName: '',
      hdTitle: '',
      hdName: '',
      pageCont: '',
      active: 0,
      slideHd: {
        title: '新闻动态',
      },
      slideList:[],
      pageSize: 10,
      pageNum: 1,
      total: 0,
      listData: [
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
          artDesc: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神集团公司机关纪委认真学习贯彻党的十九届六中全会精神集团公司机关纪委认真学习贯彻党的十九届六中全会精神集团公司机关纪委认真学习贯彻党的十九届六中全会精神集团公司机关纪委认真学习贯彻党的十九届六中全会精神'
        }]
    }
  },
  mounted(){
    var _this = this
    _this.active = _this.$route.params.parentId
    _this.listId = _this.$route.params.id
    _this.parentId = _this.$route.params.parentId
    // _this.getChildChannel()
    _this.getChannelInfo()
    _this.getArtInfo()
    _this.increaseViews()
  },
  methods: {
    // 查询二级栏目
    getChildChannel(id) {
      var _this = this

      let data = {
        applicationUuid: _this.webId,
        parentUuid: id
      }
      getChildChannel(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.slideList = data
          _this.slideHdParentName = data[0].name
          let url = ''
          if (data[0].catalog === 1) {
            url = `/listPage/${data[0].entTChannelUuid}`
          } else if (data[0].catalog === 2) {
            url = `/list/${data[0].entTChannelUuid}`
          } else {
            url = `/listPic/${data[0].entTChannelUuid}`
          }
          _this.slideHdParentUrl = url
          // if (_this.slideList.length) {
          //   _this.viewSlideHdParent = true
          // } else {
          //   _this.viewSlideHdParent = false
          // }
        }
      })
    },
    getChannelInfo() {
      var _this = this
      let data = {
        channelUuid: _this.$route.params.parentId
      }
      getChannelInfo(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.slideHdName = data.name
          // _this.slideHdParentName = data.name
          // let url = ''
          // if (data.catalog === 1) {
          //   url = `/listPage/${_this.$route.params.parentId}`
          // } else if (data.catalog === 2) {
          //   url = `/list/${_this.$route.params.parentId}`
          // } else {
          //   url = `/listPic/${_this.$route.params.parentId}`
          // }
          // _this.slideHdParentUrl = url
          if (data.parentUuid == "0") {
            _this.slideHdTitle = data.name
            // _this.viewSlideHdParent = false
            return
          }

          _this.getChildChannel(data.parentUuid)

          let params = {
            channelUuid: data.parentUuid
          }
          getChannelInfo(params).then(res => {
            const { code, data } = res
            if (code === 1 && data) {
              _this.slideHdTitle = data.name
            }
          })
        }
      })
    },
    // 
    getArtInfo() {
      var _this = this
      var applicationId = ''
      if (_this.$route.params.parentId == '01370001') {
        applicationId = _this.webIdGroup
      } else {
        applicationId = _this.webId
      }
      let data = {
        entTApplicationUuid: applicationId,
        entTArticleUuid: _this.$route.params.id
      }
      getArtInfo(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.pageCont = data
        }
      })
    },
    increaseViews(){
      var _this = this
      let data = {
        entTArticleUuid: _this.$route.params.id
      }
      increaseViews(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          // _this.dataView = data
          // console.log(111,_this.dataView)
        }
      })
    }
  }
}
</script>